import React, { Fragment, useState } from 'react';
import DorpleLogo from '../images/logo-large.svg';
import "@fontsource/raleway/700.css";
import '@fontsource-variable/outfit';
import { useTranslation } from 'react-i18next';
import ScoringBadge from '../components/ScoringBadge';
import '@khmyznikov/pwa-install';


function HomePage() {
  const { t } = useTranslation();

  return (
    <div>
      <pwa-install></pwa-install>
      <div className="text-center p-5 pb-10">
        <div id="scoringbadge" className="w-46 max-w-46 p-4 flex justify-center items-center">
          <img src={DorpleLogo} alt="Dorple Logo" className="size-26 max-size-26"/>
        </div>
        <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
        <p className="text-2xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
      </div>
      <ScoringBadge />
    </div>
  );
}

export default HomePage;
