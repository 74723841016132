import { useLocation, useNavigate } from 'react-router-dom';

import { TiTick, TiTimes } from "react-icons/ti";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function BuildingDetailPage(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const character = location.state?.dorple

  console.log("Displaying", character)

  return (

<div id="building-detail-container" className='flex'>
  <div className="max-h-12 h-12 w-full flex items-start justify-left p-2">
    <button
     onClick={() => navigate(-1)}
    className='p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10'
    type='button'>
                  <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>
    </button>
  </div>

  <div className="flex items-center justify-center">
    <div className="relative vh-64 hw-64">
      <div
              style={{ backgroundImage: `url(${character.imageLink})` }}
              className='card border grid grid-cols-1 gap-0 content-end'
            >
                <div className="flex flex-row  align-middle m-2 bg-white opacity-75 rounded-md">
                  <div className="m-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeLinejoin="1.5" stroke="currentColor" className="size-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                    </svg>
                  </div>
                  <div className="m-1 font-bold text-sm">{character.name}</div>
                </div>

                <div className="flex flex-row align-middle m-2 mt-0 bg-white opacity-75 rounded-md">
                  <div className="m-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeLinejoin="1.5" stroke="currentColor" className="size-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                  </div>
                  <div className="m-1 text-sm">{character.location}</div>
                </div>

                <div className=" flex flex-row align-middle items-start  ml-2 mb-2 ">
                    <div className="flex flex-row items-start align-middle mr-2 bg-white opacity-80 rounded-md">
                    <div className="rounded-full bg-red-600 text-white m-1"><TiTimes className="w-5 h-5" /></div> <div className="m-1 text-sm">{character.scoren}</div></div>
                    <div className="flex flex-row items-start align-middle bg-white opacity-80 rounded-md">
                    <div className="rounded-full bg-green-600 text-white m-1"><TiTick className="w-5 h-5" /></div> <div className="m-1 text-sm">{character.scorey}</div></div>
                </div>
      </div>
    </div>
  </div>
    </div>
  );

}

export default BuildingDetailPage;    