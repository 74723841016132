import { useEffect, useState } from 'react';

import ScoreDoughnut from '../components/ScoreDoughnut';
import { useAccountService } from '../services/AccountService';

function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 500);
  console.log('swipa page reload');
}

export default function SwipeCongrats() {
  const accountService = useAccountService();
  const [userStats, setUserStats] = useState(null);

  useEffect(() => {
    console.log('Fetching stats...');
    accountService.getStats()
      .then(stats => {
        console.log('Stats received:', stats);
        if (!stats) {
          console.error('No stats data received from service');
          return;
        }
        setUserStats({
          scoredBuildings: stats.totalVotes || 0,
          upvotedBuildings: stats.totalLikes || 0,
          downvotedBuildings: stats.totalDislikes || 0
        });
      })
      .catch(ex => {
        console.error("Failed to fetch stats:", ex);
      });
  }, [accountService]);

  console.log('Current userStats:', userStats);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }} className="flex flex-col items-center justify-center">
      {userStats ? (
        <ScoreDoughnut 
          scoredBuildings={userStats.scoredBuildings} 
          upvotedBuildings={userStats.upvotedBuildings} 
          downvotedBuildings={userStats.downvotedBuildings} 
        />
      ) : (
        <p>Loading stats...</p>
      )}
      <h1 className="text-4xl text-sky-800 apptitle font-bold  pt-10">Waauw</h1>
      <p className="text-lg text-sky-800 ">Je bent op weg een echte GevelGeek te worden.</p>

      <div className="flex w-full justify-center pt-10">
        <button onClick={refreshPage} className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2  rounded">Verder Swipen</button>
      </div>
    </div>
  );
}