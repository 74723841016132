import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRequestService } from '../services/RequestService';
import DorpleLogo from '../images/logo-large.svg';

function ResendVerificationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const requestService = useRequestService();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      await requestService.post('auth/resend-verification', { email });
      setSuccess(true);
    } catch (err) {
      setError(t('verification.resend.error'));
      console.error('Verification resend error:', err);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center vh-100 m-4 vw-100">
        <div id="logo" className="flex flex-col justify-center items-center w-full pb-2 mb-3">
          <img className="bg-white bg-opacity-75 border-sky-800 w-14 h-14 max-w-14 border rounded-full m-2" src={DorpleLogo} alt="Dorple Logo" />
          <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
          <p className="text-2xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
        </div>

        <div className="border max-w-md rounded-lg shadow-md p-4">
          {!success ? (
            <form onSubmit={handleSubmit}>
              <h2 className="text-xl font-bold mb-4 text-center">{t('verification.resend.title')}</h2>
              <p className="text-sm text-gray-600 mb-4">{t('verification.resend.description')}</p>
              
              {error && <p className="text-red-600 text-sm mb-4">{error}</p>}
              
              <input
                className="border appearance-none rounded w-full p-2 text-gray-700 leading-tight focus:outline-none bg-white border-gray-300 focus:bg-sky-50 focus:border-sky-700"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('login.email')}
                required
              />
              
              <button
                type="submit"
                className="w-full mt-4 shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md p-2 rounded"
              >
                {t('verification.resend.submit')}
              </button>
            </form>
          ) : (
            <div className="text-center">
              <p className="text-green-600 mb-4">{t('verification.resend.success')}</p>
              <button
                onClick={() => navigate('/login')}
                className="text-sky-600 hover:underline"
              >
                {t('login.button')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResendVerificationPage; 