import { useEffect, useState } from 'react';
import { useRequestService } from '../services/RequestService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BadgeUrl from "../images/compass.svg";
import GiftUrl from "../images/gift.svg";





function ScoringBadge() {
  const { t } = useTranslation();
  const requestService = useRequestService();
  const [currentBadge, setCurrentBadge] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  
  const fetchCurrentScoringBadge = async () => {
    try {
      const badges = await requestService.get('badges/badges');
      
      // Filter for scoring badges and get the highest one
      const scoringBadges = badges.filter(badge => 
        badge.type.startsWith('SCORER_')
      ).sort((a, b) => {
        const levels = { BRONZE: 1, SILVER: 2, GOLD: 3 };
        const aLevel = levels[a.type.split('_')[1]];
        const bLevel = levels[b.type.split('_')[1]];
        return bLevel - aLevel;
      });
      
      return scoringBadges[0] || {
        type: 'NEWCOMER',
        awardedAt: [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()]
      };
    } catch (error) {
      console.error('Error fetching scoring badge:', error);
      return {
        type: 'NEWCOMER',
        awardedAt: [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()]
      };
    }
  };

  useEffect(() => {
    fetchCurrentScoringBadge().then(badge => {
      setCurrentBadge(badge);
      setIsLoading(false);
    });
  }, []);

  if (isLoading || !currentBadge) return null;

  // Format the badge type for display
  const badgeLevel = currentBadge.type.split('_')[1]?.toLowerCase() || 'newcomer';
  
  // Set badge color based on level
  const badgeColor = {
    'bronze': 'bg-gradient-to-br from-orange-800 via-orange-600 to-orange-800',
    'silver': 'bg-gradient-to-br from-gray-500 via-gray-300 to-gray-500',
    'gold': 'bg-gradient-to-br from-yellow-500 via-yellow-300 to-yellow-500',
    'newcomer': 'bg-gradient-to-br from-lime-600 via-lime-400 to-lime-600'
  }[badgeLevel];

  // Format the awarded date
  const awardedDate = currentBadge?.dateAwarded ? new Date(
    currentBadge.dateAwarded[0],
    currentBadge.dateAwarded[1] - 1,
    currentBadge.dateAwarded[2]
  ).toLocaleDateString() : new Date().toLocaleDateString();



  return (
    <div 
      className="p-2 rounded-md flex flex-col items-center cursor-pointer "
      onClick={() => navigate('/rewards')}
    >
      <div className={`rounded-full ${badgeColor} border border-sky-800 w-14 h-14 flex items-center justify-center`}>
        <img 
          src={badgeLevel === 'newcomer' ? GiftUrl : BadgeUrl} 
          alt={badgeLevel === 'newcomer' ? t('badges.NEWCOMER.title') : t(`badges.${currentBadge.type}.title`)} 
          className="w-10 h-10 stroke-sky-800" 
        />
      </div>
      <h3 className="font-bold text-sky-800">
        {badgeLevel === 'newcomer' ? t('badges.NEWCOMER.title') : t(`badges.${currentBadge.type}.title`)}
      </h3>
      <p className="text-xs text-gray-600">{t('rewards.awarded')}  {awardedDate}
      </p>
    </div>
  );
}

export default ScoringBadge;