import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequestService } from '../services/RequestService';
import { useTranslation } from 'react-i18next';

function SendInvitationPage() {
  const { t } = useTranslation();
  const requestService = useRequestService();
  const navigate = useNavigate();
  const [emails, setEmails] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const validateEmails = (emailString) => {
    if (!emailString.trim()) {
      return { isValid: false, error: t('invite.errorEmptyEmails') };
    }

    const emailList = emailString.split(',').map(email => email.trim());
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    
    const invalidEmails = emailList.filter(email => !emailRegex.test(email));
    
    if (invalidEmails.length > 0) {
      return {
        isValid: false,
        error: t('invite.errorInvalidEmails') + invalidEmails.join(', ')
      };
    }

    return { isValid: true, error: null };
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmails(value);
    setEmailError(null); // Clear previous error when typing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setEmailError(null);

    const validation = validateEmails(emails);
    if (!validation.isValid) {
      setEmailError(validation.error);
      setIsLoading(false);
      return;
    }

    try {
      const emailList = emails.split(',').map(email => email.trim());
      await requestService.post('invites/send', {
        emails: emailList,
        message: message
      });
      navigate('/invite', { state: { success: true } });
    } catch (err) {
      if (err.response?.data?.alreadyInvited) {
        navigate('/invite', { 
          state: { 
            error: t('invite.errorAlreadyInvited') + err.response.data.alreadyInvited.join(', ')
          }
        });
      } else {
        setError(t('invite.errorMessage'));
        setIsLoading(false);
      }
      console.error('Error sending invites:', err);
    }
  };

  return (
<div className="flex flex-col">
      <div id="invite-nav" className="fixed top-0 w-full bg-white text-sm font-medium border-b border-gray-200">
      <div className="flex flex-row -mb-px max-w-lg mx-auto">
        <div className="flex flex-row items-center justify-center p-2 pl-0">
          <button
            onClick={() => navigate('/invite')}
            className='p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10'
            type='button'
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>
          </button>
        </div>
        <div className="flex items-center justify-center align-middle text-lg font-bold text-sky-800">
          {t('invite.title')}
        </div>
      </div>
      </div>

      <form onSubmit={handleSubmit} className="p-6 mt-4">
        <div className="mb-4">
          <label htmlFor="emails" className="block text-sm font-medium text-gray-700 mb-2">
            {t('invite.emailLabel')}*
          </label>
          <input
            type="text"
            id="emails"
            value={emails}
            onChange={handleEmailChange}
            className={`w-full p-2 border rounded focus:ring-sky-500 focus:border-sky-500 
              ${emailError ? 'border-red-500' : 'border-gray-300'}`}
            placeholder={t('invite.emailPlaceholder')}
            required
          />
          {emailError ? (
            <p className="text-xs text-red-500 mt-1">
              {emailError}
            </p>
          ) : (
            <p className="text-xs text-gray-500 mt-1">
              {t('invite.emailHelper')}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
            {t('invite.messageLabel')}
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded focus:ring-sky-500 focus:border-sky-500"
            rows="3"
            placeholder={t('invite.messagePlaceholder')}
          />
        </div>

        {error && (
          <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="flex gap-4">
          <button
            type="submit"
            disabled={isLoading || emailError}
            className={`flex-1 bg-sky-800 text-white py-2 px-4 rounded 
              ${(isLoading || emailError) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isLoading ? t('invite.sending') : t('invite.sendButton')}
          </button>
        </div>
      </form>
    </div>
  );
}

export default SendInvitationPage; 