import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRequestService } from '../services/RequestService';
import DorpleLogo from '../images/logo-large.svg';

function VerifyEmailPage() {
  const { token } = useParams();
  const [status, setStatus] = useState('verifying'); // 'verifying', 'success', or 'error'
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const requestService = useRequestService();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await requestService.get(`auth/verify?token=${token}`);
        setStatus('success');
        setMessage(t('verified.success'));
      } catch (error) {
        setStatus('error');
        setMessage(t('verified.error'));
      }
    };

    verifyEmail();
  }, [token, requestService, t]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <Link
        to={`/login`}
        id="logo" 
        className="flex flex-col justify-center items-center w-full pb-2 mb-3"
      >
        <img className="bg-white bg-opacity-75 border-sky-800 w-14 h-14 max-w-14 border rounded-full m-2" src={DorpleLogo} alt="Dorple Logo" />
        <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
        <p className="text-2xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
      </Link>

      <div className="w-full max-w-md border rounded-lg shadow-md p-4 bg-white">
        {status === 'verifying' && (
          <div className="text-center">
            <p>{t('verified.verifying')}</p>
          </div>
        )}
        
        {status === 'success' && (
          <div className="text-center">
            <p className="text-green-600">{message}</p>
            <Link to="/login" className="text-sky-600 hover:text-sky-800 mt-4 block">
              {t('verified.loginLink')}
            </Link>
          </div>
        )}
        
        {status === 'error' && (
          <div className="text-center">
            <p className="text-red-600">{message}</p>
            <Link to="/login" className="text-sky-600 hover:text-sky-800 mt-4 block">
              {t('verified.tryAgain')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyEmailPage; 