export const storageKeys = {
  token: "jwt",
  email: "email",
  username: "username",
  localswipe: "localswipe",
  geolocation: "geolocation"
}

export default class StorageService {

  /**
   * Save 
   * @param {string} key The key to use to store the item.
   * @param {string | object} value The value to store.
   */
  save(key, value) {
    localStorage.setItem(key, value);
  }

  /**
   * Load item
   * @param {string} key The key of the stored item.
   */
  load(key) {
    return localStorage.getItem(key);
  }
  /**
   * Removes an item from storage
   * @param {string} key The key of the stored item.
   */
  remove(key) {
    localStorage.removeItem(key);
  }

  saveToken(token) {
    if (token) {
      localStorage.setItem('jwt', token.trim());
    }
  }

  saveEmail = email => this.save(storageKeys.email, email);
  saveUsername = username => this.save(storageKeys.username, username);
  saveLocalswipe = localswipe => this.save(storageKeys.localswipe, localswipe);
  saveGeolocation = coords => this.save(storageKeys.geolocation, JSON.stringify(coords));

  loadToken() {
    const token = localStorage.getItem('jwt');
    return token ? token.trim() : null;
  }

  loadUsername = this.load.bind(this, storageKeys.username);
  loadEmail = this.load.bind(this, storageKeys.email);
  removeToken = this.remove.bind(this, storageKeys.token);
  loadLocalswipe = this.load.bind(this, storageKeys.localswipe);
  loadGeolocation = () => {
    const coords = this.load(storageKeys.geolocation);
    return coords ? JSON.parse(coords) : null;
  };
  removeGeolocation = () => this.remove(storageKeys.geolocation);

  removeEmail() {
    localStorage.removeItem('email');
  }

  removeUsername() {
    localStorage.removeItem('username');
  }
}