import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestService } from '../services/RequestService';
import { useAuthenticationService } from '../services/AuthenticationService';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const requestService = useRequestService();
  const authenticationService = useAuthenticationService();

  const changeLanguage = async (language) => {
    await i18n.changeLanguage(language);
    
    // Only update in backend if user is authenticated
    if (authenticationService.isTokenValid()) {
      try {
        await requestService.updateLanguage(language);
      } catch (error) {
        console.error('Failed to update language preference:', error);
      }
    }
  };

  return (
    <div className="flex flex-row space-x-2">
      <button
        className={`px-2 py-1 rounded ${i18n.language === 'nl' ? 'text-sky-800' : 'text-gray-400'} text-lg`}
        onClick={() => changeLanguage('nl')}
      >
        NL
      </button>
      <button
        className={`px-2 py-1 rounded ${i18n.language === 'en' ? 'text-sky-800' : 'text-gray-400'} text-lg`}
        onClick={() => changeLanguage('en')}
      >
        EN
      </button>
      <button
        className={`px-2 py-1 rounded ${i18n.language === 'fr' ? 'text-sky-800' : 'text-gray-400'} text-lg`}
        onClick={() => changeLanguage('fr')}
      >
        FR
      </button>
      <button
        className={`px-2 py-1 rounded ${i18n.language === 'de' ? 'text-sky-800' : 'text-gray-400'} text-lg`}
        onClick={() => changeLanguage('de')}
      >
        DE
      </button>
    </div>
  );
}

export default LanguageSwitcher; 