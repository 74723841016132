import React, { useState, useEffect } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InfiniteScroll from "react-infinite-scroll-component";
import { Puff } from "react-loader-spinner";
import { useBuildingService } from "../services/BuildingService";
import GalleryEmpty from "../components/GalleryEmpty";

function GalleryPage() {
  const { trackPageView } = useMatomo()
  const [dorples, setDorples] = useState([]); // Hier slaan we de data op
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const buildingService = useBuildingService();

  const fetchMoreData = () => {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        buildingService.getGallery(index).then(data => {
          setDorples((prevDorples) => [...prevDorples, ...data]);
          setHasMore(data.length > 0);
          setIndex((prevIndex) => prevIndex + 1);
          setIsLoading(false);
        }).catch(error => {
          console.error("Error fetching data:", error)
          setIsLoading(false);
        });
      } else console.log("I'm fetching data;");
  };

  useEffect(() => {
    fetchMoreData();
    trackPageView();
  }, []); // Empty dependency array means run once on mount

  return (


<div className="flex flex-col">
  <div id="gallery-nav" className="fixed top-0 z-50 w-full bg-white text-sm font-medium text-center border-b border-gray-200">
    <ul className="flex flex-row -mb-px max-w-lg mx-auto">
    <li className="me-2 w-1/2 border-b-4 border-sky-800 bg-sky-50">
        <a href="/gallery" className="flex items-center justify-center inline-block  p-3 text-sky-800 text-bold">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
        </svg>
        <span class="font-semibold text-sky-800">
             GALLERY
        </span>
        </a>
      </li>
      <li className="me-2 w-1/2 border-b-4 border-transparent">
        <a href="/gallerymap" className="flex items-center justify-center inline-block  p-3 text-bold">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
        </svg>
        <span class="font-semibold text-gray-700">
             KAART
        </span>        
        </a>
      </li>
    </ul>
  </div>
  
  <div id="gallery-container" className="mx-auto max-w-lg pt-16 z-0 h-screen overflow-auto">
    {
      dorples && !dorples.length && !hasMore ? <GalleryEmpty /> :
    <InfiniteScroll
      dataLength={dorples.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={         <div className="flex items-center justify-center w-full min-h-[80vh] col-span-3">
        <Puff
          visible={true}
          height="70"
          width="70"
          color="#0C4A6E"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>}
      scrollThreshold={0.8}
      className="grid grid-cols-3 gap-2 sm:grid-cols-3 md:grid-cols-3"
      scrollableTarget="gallery-container"
    >
      {dorples &&
        dorples.map((dorple, index) => (
          <div className="gallery-tile" key={index}>
            <Link
              to={`/gallerydetail`}
              state={{ dorple: dorple }}
              className="bg-sky-50"
            >
              <LazyLoadImage
                className="w-full max-w-full h-[20vh] md:h-[30vh] object-cover object-center overflow-hidden overflow-hidden"
                src={dorple.imageLink}
                alt="gallery-photo"
                placeholderSrc={dorple.imageLink}
              />
                 <progress className="[&::-webkit-progress-bar]:bg-green-600 [&::-webkit-progress-value]:bg-red-600 [&::-moz-progress-bar]:bg-red-600" value={dorple.scoren} max={dorple.scoren + dorple.scorey}> </progress>
            </Link>
          </div>
        ))}
    </InfiniteScroll>
    }
  </div>
</div>



  );
}

export default GalleryPage;