import { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import RequestService from './RequestService';
import StorageService from './StorageService';

/**
 * 
 */
export default class AuthenticationService {
  _token;
  _requestService;
  storageService;

  constructor(requestService) {
    this._requestService = requestService;
    this.storageService = new StorageService();
  }

  /**
   * @type { RequestService }
   */
  get requestService() {
    if (!this._requestService) this._requestService = new RequestService();
    return this._requestService;
  }
  
  get token() {
    // First check cached token
    if (this._token && this.isTokenValid(this._token)) {
        return this._token;
    }
    // Then check storage
    const loadedToken = this.storageService.loadToken();
    if (loadedToken) {
        this._token = loadedToken;  // Store the loaded token
        if (this.isTokenValid(this._token)) {
            return this._token;
        }
    }
    return null;
  }

  /**
   * Checks whether a JWT is still valid.
   * @param {string} token The JWT to validate.
   * @returns `true` if the token is valid.
   */
  isTokenValid(token = null) {
    try {
        if (!token) token = this.token;
        if (!token) {
            return false;
        }

        const decoded = this.decodeToken(token);
        if (!decoded || !decoded.exp) {
            console.debug("Token invalid - no expiration:", decoded);
            this.clearToken(false);
            return false;
        }

        const currentTime = Date.now() / 1000;
        const isValid = decoded.exp > currentTime;

        if (!isValid) {
            console.debug("Token expired:", {
                exp: new Date(decoded.exp * 1000),
                now: new Date()
            });
            this.clearToken(true);
        }

        return isValid;
    } catch (error) {
        console.error("Token validation error:", error);
        this.clearToken(false);
        return false;
    }
  }

  decodeToken(token = null) {
    if (!token) return null;
    
    try {
        const decoded = jwtDecode(token);
        console.debug("Decoded token:", {
            exp: new Date(decoded.exp * 1000),
            sub: decoded.sub,
            iat: new Date(decoded.iat * 1000)
        });
        return decoded;
    } catch (error) {
        console.error("Error decoding token:", error);
        return null;
    }
  }

  get decodedToken() {
    return this.decodeToken(this.token);
  }

  get email() {
    return this.decodedToken ? this.decodedToken.email : null;
  }

  get roles() {
    return this.decodedToken ? this.decodedToken.roles : null;
  }

  async signIn(email, password) {
    try {
        const response = await this.requestService.post('auth/signin', {
            email,
            password
        });

        if (response && response.token) {
            // Store the token without any whitespace
            this._token = response.token.trim();  // Cache the token
            this.storageService.saveToken(this._token);
            this.storageService.saveEmail(response.email);
            this.storageService.saveUsername(response.username);
            
            return {
                status: true,
                ...response
            };
        } 
        else if (response.type === 'info' && response.message === 'User is disabled') {
          // Handle disabled/unverified user case
          return {
            status: false,
            message: "User is disabled"
        }
      } 
        
      
        else {
            console.error("Invalid login response:", response);
            return {
                status: false,
                message: "Invalid login response from server"
            };
        }
    } catch (error) {
        console.error("Login error:", error);
        if (error.response?.status === 401) {
            return {
                status: false,
                message: error.response.data.message,
                type: 'info'
            };
        }
        return {
            status: false,
            message: "Authentication failed"
        };
    }
  }

  async signOut() {
    // Clear the cached token
    this._token = null;
    
    // Clear all storage
    this.storageService.removeToken();
    this.storageService.removeEmail();
    this.storageService.removeUsername();
    
    // Clear any other auth-related storage
    localStorage.clear();  // This will clear all localStorage items
    
    console.log("Signed out, all auth data cleared");
  }

  async requestPasswordReset(email) {
    try {
      // Log the request payload for debugging
      console.log('Sending password reset request with:', { email });
      
      // Make sure email is sent as an object with email property
      const response = await this.requestService.post('auth/request-password-reset', { 
        email: typeof email === 'string' ? email : email.email 
      });
      return response;
    } catch (error) {
      console.error('Request password reset error:', error);
      throw error;
    }
  }

  clearToken(wasExpired = false) {
    this._token = null;
    this.storageService.removeToken();
    this.storageService.removeEmail();
    this.storageService.removeUsername();
    
    if (wasExpired) {
      console.log("Token is verlopen. Uitloggen...");
      this.signOut();
    }
  }

}

export function useAuthenticationService() {
  const [authenticationService] = useState(new AuthenticationService());
  return authenticationService;
}