import React from "react";
import LinkedinUrl from "../images/linkedin.svg";
import { useTranslation } from 'react-i18next';

export default function SocialFollow() {
  const { t } = useTranslation();
  return (
    <div class="social-container flex flex-row justify-center items-center">
        <div class="text-sky-800 text-sm">{t('home.follow_us')}</div>

        <a href="https://www.linkedin.com/company/dorple/" target="_blank" rel="noreferrer">
            <div className="rounded-full bg-sky-800  m-2 p-2">
            <img  src={LinkedinUrl} className="stroke-white fill-white size-4" alt="{t('home.linkedin')}" />
            </div>
        </a>

    </div>
  );
}