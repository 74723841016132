import { useState } from 'react';
import RequestService from './RequestService';

export default class AccountService {
  baseAddress;
  requestService;

  constructor() {
    this.requestService = new RequestService();
  }

  async getStats() {
    return await this.requestService.get("account/stats");
  }

  async getUserName() {
    try {
      const response = await this.requestService.post('account/name', {});
      return response.name;
    } catch (error) {
      console.error('Error fetching user name:', error);
      throw error;
    }
  }

  async setUserName(name) {
    try {
      const response = await this.requestService.post('account/name', { name });
      return response.name;
    } catch (error) {
      console.error('Error updating user name:', error);
      throw error;
    }
  }
}

/**
 * 
 * @returns {AccountService}
 */
export function useAccountService() {
  const [accountService] = useState(new AccountService());
  return accountService;
}