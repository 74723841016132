import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useRequestService } from '../services/RequestService';
import { useTranslation } from 'react-i18next';

function formatDate(dateString) {
  if (!dateString) return '';
  try {
    return new Date(dateString).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (e) {
    console.error('Error formatting date:', e);
    return '';
  }
}

function InvitePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const requestService = useRequestService();
  const [invites, setInvites] = useState([]);
  const location = useLocation();
  const { success, error } = location.state || {};

  const fetchInvites = async () => {
    try {
      const data = await requestService.get('invites/list');
      setInvites(data);
    } catch (err) {
      console.error('Error fetching invites:', err);
    }
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  return (
    <div className="flex flex-col">
    <div id="invite-nav" className="fixed top-0 z-40 h-14 max-h-14 w-full bg-white text-sm font-medium border-b border-gray-200">
    <div className="flex flex-row items-center -mb-px max-w-lg mx-auto">
      <div className="flex items-center justify-center p-2 pl-0">
        <button
          onClick={() => navigate('/rewards ')}
          className='p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10'
          type='button'
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
        </button>
      </div>
      <div className="flex-1 w-64 items-center justify-center align-left text-left text-lg font-bold text-sky-800">
        {t('invite.sentTitle')}
      </div>
      <div className="flex flex-row items-center justify-center">
      <Link 
          to="/send-invitation"
          className="text-sky-800 p-3 w-full"
        >
          
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
        </svg>
        </Link>
      </div>
    </div>
    </div>

    <div className="w-full pt-16">

      {success && (
        <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">
          {t('invite.successMessage')}
        </div>
      )}

      {error && (
        <div className="mb-4 p-2 bg-yellow-100 text-yellow-700 rounded">
          {error}
        </div>
      )}

      <div className="mx-auto max-w-lg pt-16 z-0 h-screen overflow-auto">
        {invites.length === 0 ? (
          <p className="text-gray-500"
          dangerouslySetInnerHTML={{ __html: t('invite.noInvites') }}
          />
        ) : (
          <div className="w-full space-y-4">
            {invites.map((invite) => (
              <div key={invite.id} className="flex items-center justify-between border-b pb-4">
                <div>
                  <p className="font-medium text-left text-gray-800">{invite.inviteeEmail}</p>
                  <p className="text-sm text-left text-gray-500">
                    {t('invite.sentDate')}: {formatDate(invite.createdAt)}

                  </p>
                  {invite.message && (
                    <p className="text-sm text-left text-gray-500 mt-1">
                      {t('invite.messageLabel')}: {invite.message}
                    </p>
                  )}
                </div>
                <div className={`px-3 py-1 rounded-full text-sm ${
                  invite.accepted 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {invite.accepted ? (
                    <>
                      {t('invite.accepted')}
                      {invite.acceptedAt && (
                        <p className="text-xs">
                          {formatDate(invite.acceptedAt)}
                        </p>
                      )}
                    </>
                  ) : t('invite.pending')}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      </div>
    </div>
  );
}

export default InvitePage; 