import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuthenticationService } from './services/AuthenticationService';

import './App.css';

import HomePage from './pages/HomePage';
import SwipePage from './pages/SwipePage';
import GalleryPage from './pages/GalleryPage';
import GallerymapPage from './pages/GallerymapPage';
import BuildingDetailPage from './pages/BuildingDetailPage';
import SettingsPage from './pages/SettingsPage';
import RewardsPage from './pages/RewardsPage';
import NavFooter from './components/NavFooter';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import OnlineIndicator from './components/OnlineIndicator';
import InvitePage from './pages/InvitePage';
import SendInvitationPage from './pages/SendInvitationPage';
import RequestPasswordResetPage from './pages/RequestPasswordResetPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ResendVerificationPage from './pages/ResendVerificationPage';
import VerifyEmailPage from './pages/VerifyEmailPage';


// Component for public routes
function PublicRoute({ children }) {
  const authenticationService = useAuthenticationService();
  const location = useLocation();

  // Check if user is authenticated
  const isAuthenticated = authenticationService.isTokenValid();

  console.log("PublicRoute check:", {
    path: location.pathname,
    isAuthenticated
  });

  if (isAuthenticated) {
    console.log("Redirecting to home because user is authenticated");
    return <Navigate to="/home" replace />;
  }

  return children;
}

// Component for protected routes
function PrivateRoute({ children }) {
  const authenticationService = useAuthenticationService();
  const isAuthenticated = authenticationService.isTokenValid();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Return both the page content and the NavFooter
  return (
    <>
      {children}
      <NavFooter />
    </>
  );
}

// Create a new component to wrap the app content
function AppContent() {
  const location = useLocation();
  const publicRoutes = ['/login', '/signup', '/terms', '/privacy', '/request-password-reset', '/reset-password', '/resend-verification', '/verify'];
  const isPublicRoute = publicRoutes.some(route => location.pathname.startsWith(route));

  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        } />
        <Route path="/signup" element={
          <PublicRoute>
            <SignupPage />
          </PublicRoute>
        } />
        <Route path="/terms" element={
          <PublicRoute>
            <TermsPage />
          </PublicRoute>
        } />
        <Route path="/privacy" element={
          <PublicRoute>
            <PrivacyPage />
          </PublicRoute>
        } />
        <Route path="/request-password-reset" element={
          <PublicRoute>
            <RequestPasswordResetPage />
          </PublicRoute>
        } />
        <Route path="/reset-password/:token" element={
          <PublicRoute>
            <ResetPasswordPage />
          </PublicRoute>
        } />
        <Route path="/resend-verification" element={
          <PublicRoute>
            <ResendVerificationPage />
          </PublicRoute>
        } />
        <Route path="/verify/:token" element={
          <PublicRoute>
            <VerifyEmailPage />
          </PublicRoute>
        } />

        {/* Protected routes */}
        <Route path="/home" element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        } />
        <Route path="/swipe" element={
          <PrivateRoute>
            <SwipePage />
          </PrivateRoute>
        } />
        <Route path="/gallery" element={
          <PrivateRoute>
            <GalleryPage />
          </PrivateRoute>
        } />
        <Route path="/gallerymap" element={
          <PrivateRoute>
            <GallerymapPage />
          </PrivateRoute>
        } />
        <Route path="/gallerydetail" element={
          <PrivateRoute>
            <BuildingDetailPage />
          </PrivateRoute>
        } />
        <Route path="/rewards" element={
          <PrivateRoute>
            <RewardsPage />
          </PrivateRoute>
        } />
        <Route path="/settings" element={
          <PrivateRoute>
            <SettingsPage />
          </PrivateRoute>
        } />
        <Route path="/invite" element={
          <PrivateRoute>
            <InvitePage />
          </PrivateRoute>
        } />
        <Route path="/send-invitation" element={
          <PrivateRoute>
            <SendInvitationPage />
          </PrivateRoute>
        } />

        {/* Redirect root to login */}
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
      <OnlineIndicator />
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
